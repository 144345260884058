import React from 'react';
import classNames from 'classnames';
import { Button, Navbar, Alignment, Classes, Colors } from '@blueprintjs/core';
import {
  withAuthenticator,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react';

import './App.css';

function App({ signOut }: WithAuthenticatorProps) {
  return (
    <section className={classNames(Classes.DARK, 'app-window')}>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <span>mimic</span>
            <span style={{ color: Colors.INDIGO5 }}>io</span>
          </Navbar.Heading>
          <Navbar.Divider />
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button
            className="bp4-minimal"
            icon="log-out"
            text="Sign out"
            onClick={signOut}
          />
        </Navbar.Group>
      </Navbar>
      <main className="app-content" style={{ background: Colors.DARK_GRAY4 }}>
        <h1>
          <span>mimic</span>
          <span style={{ color: Colors.INDIGO5 }}>io</span>
        </h1>
      </main>
    </section>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
